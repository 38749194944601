import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type ApiPayloadUsed = {
  brand: string;
  country: string;
  indication?: string;
  targetAudience?: (string | undefined)[];
  language?: string;
  topics?: (string | undefined)[];
  claimsPage?: number;
  claimsPageSize?: number;
};

interface Segment {
  id: string;
  name: string;
  label: string;
  description: string;
}

interface AdditionalBrandDetail {
  indicationName: string;
  indicationBrandDescription: string;
}

interface PrescribingInfo {
  id: string;
  label: string;
  link: string;
}

interface SafetyInfo {
  id: string;
  text: string;
}

interface SupportingInfo {
  audienceName: string;
  prescribingInfo: PrescribingInfo[];
  safetyInfo: SafetyInfo;
}

export interface Indication {
  id: string;
  name: string;
  label: string;
  shortName: string;
  longName: string;
  inclusions: string[];
  exclusions: string[];
}

export interface BrandDetails {
  id: string;
  name: string;
  description: string;
  additionalBrandDetails: AdditionalBrandDetail[];
  supportingInfo: SupportingInfo[];
  indications: Indication[];
}

export interface SegmentDetail {
  audience: string;
  segments: Segment[];
}

export interface ClaimDetails {
  id: string;
  code: string;
  text: string;
  revisedText: string;
  primaryMessage: string;
  secondaryTopics: string[];
  referenceLink: string;
  references: string[];
  rank: number;
}

export interface Pagination {
  total: number;
  page: number;
  totalPages: number;
  nextPage: number | null;
  previousPage: number | null;
}

type BrandInformation = {
  apiPayloadUsed: ApiPayloadUsed;
  brandDetails: BrandDetails;
  segmentDetails: SegmentDetail[];
  claimDetails: ClaimDetails[];
  claimDetailsPagination: Pagination;
  isLoading: boolean;
  errorMessage: string;
  errorFetchSegment: boolean;
};

export const brandInformationStateSegment =
  stateSegmentWithReset<BrandInformation>({
    apiPayloadUsed: {
      brand: '',
      country: '',
      indication: '',
      targetAudience: [],
      language: '',
      topics: [],
    },
    brandDetails: {
      id: '',
      name: '',
      description: '',
      additionalBrandDetails: [],
      supportingInfo: [],
      indications: [],
    },
    segmentDetails: [],
    claimDetailsPagination: {
      total: 0,
      page: 0,
      totalPages: 0,
      nextPage: null,
      previousPage: null,
    },
    claimDetails: [],
    isLoading: false,
    errorMessage: '',
    errorFetchSegment: false,
  });

brandInformationStateSegment.debugLabel = 'brandInformationStateSegment';
