import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type: 'submit' | 'reset' | 'button'; // Specify the allowed button types
  isDisabled?: boolean; // Disabled state for the button
  className?: string;
  children: ReactNode;
  colorScheme: string;
  variant: string;
  size?: string;
  testId?: string;
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  isDisabled = false,
  className,
  children,
  colorScheme,
  variant,
  size,
  testId,
  ...rest
}) => {
  const color: { [key: string]: string } = {
    primary: 'var(--pfz-interactive-primary-on-light)',
    light: 'var(--color-bg-light)',
    gray: 'var(--pfz-color-gray-500)',
    dark: 'var(--pfz-color-black)',
    error: 'var(--pfz-border-secondary-error)',
  };
  const borderColor: { [key: string]: string } = {
    gray: 'var(--pfz-color-gray-150)',
  };
  const variants = {
    solid: {
      backgroundColor: color[colorScheme],
      border: `1px solid ${color[colorScheme]}`,
    },
    outline: {
      border: `2px solid ${color[colorScheme]}`,
      color: color[colorScheme],
    },
    ghost: {
      backgroundColor: color[colorScheme],
      opacity: '0.5',
    },
    contained: {
      border: `1px solid ${borderColor[colorScheme]}`,
      color: color[colorScheme],
    },
    link: {
      color: color[colorScheme],
    },
  };
  const getStyle = () => {
    if (variant === 'solid') {
      return variants.solid;
    }
    if (variant === 'outline') {
      return variants.outline;
    }
    if (variant === 'ghost') {
      return {};
    }
    if (variant === 'contained') {
      return variants.contained;
    }
    if (variant === 'link') {
      return variants.link;
    }
    return {};
  };

  const getClass = () => {
    if (variant === 'solid') {
      if (size === 'lg') {
        return `${styles.solid__btn} ${styles.size__lg}`;
      }
      if (size === 'm') {
        return `${styles.solid__btn} ${styles.size__m}`;
      }
      if (size === '3xl') {
        return `${styles.solid__btn} ${styles.size__3xl} ${styles.font__3xl}`;
      }
      return styles.solid__btn;
    }
    if (variant === 'outline') {
      if (size === 'lg') {
        return `${styles.outline__btn} ${styles.size__lg}`;
      }
      if (size === 'xs') {
        return `${styles.outline__btn} ${styles.size__xs}`;
      }
      return styles.outline__btn;
    }
    if (variant === 'ghost') {
      if (size === 's') {
        return `${styles.ghost__btn} ${styles.size__s}`;
      }
      return styles.ghost__btn;
    }
    if (variant === 'contained') {
      return styles.contained__btn;
    }
    if (variant === 'link') {
      if (size === '3xl') {
        return `${styles.link__btn} ${styles.font__3xl}`;
      }
      return styles.link__btn;
    }
    return {};
  };

  return (
    <button
      type={type}
      style={getStyle()}
      disabled={isDisabled}
      className={`${styles.button} ${className || ''} ${getClass()} ${
        isDisabled ? styles['btn--disabled'] : ''
      }`}
      data-testid={testId}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.displayName = 'Button';
export default Button;
