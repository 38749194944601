import store from '../..';
import { GET_BRAND_DETAILS } from '../../../../constants/apiRoutes';
import { ApiError } from '../../../../types/Content';
import { httpClient } from '../../../../utils/httpClient';
import Logger from '../../../../utils/logger';
import {
  ApiPayloadUsed,
  brandInformationStateSegment,
  BrandDetails,
} from '../../stateSegments/brandsInformationStateSegment';

export interface BrandDetailsResponse {
  success: boolean;
  data?: {
    brandDetails: BrandDetails;
  };
}

export const getBrandDetails = async (
  apiPayload: ApiPayloadUsed
): Promise<BrandDetailsResponse> => {
  const {
    brand,
    country,
    indication,
    targetAudience,
    // language,
    // topics
  } = apiPayload;

  try {
    const response = await httpClient.get<BrandDetailsResponse>(
      GET_BRAND_DETAILS(
        country,
        brand,
        indication,
        targetAudience
        // includes,
        // claimSource
      )
    );

    const { data, success } = response as BrandDetailsResponse;

    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const fetchBrandDetails = async (payload: ApiPayloadUsed) => {
  store.set(brandInformationStateSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
    };
  });

  const { success, data } = await getBrandDetails({
    ...payload,
  });

  if (success && data) {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        brandDetails: data.brandDetails,
      };
    });
  } else {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        errorFetchSegment: true,
        errorMessage: 'There has been an error in fetching segment details',
      };
    });
  }
};
