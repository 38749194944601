import get from 'lodash/get';
import {
  ApiError,
  BannerFramesListType,
  CannedParametersResult,
  ContentImages,
  // ContentResult,
  ContentGeneratedResult,
  EmailContentResult,
  FeedbackResult,
  // GenerateResultData,
  GeneratedBannerContentData,
  GeneratedContentResult,
  GeneratedModuleContent,
  GeneratedSocialContentData,
  GetGeneratedContentParams,
  ImagesRecommended,
  ImageApiResponse,
  ImageData,
  ReferencesResponse,
  ReferencesResult,
  TrackContentCopyResult,
  TranslatedContent,
  ContentTranslatedResult,
} from '../types/Content';
import {
  FeedbackData,
  MarketingFormField,
  MarketingFormFieldRequest,
  PregenerateClaimsFeild,
  SecondaryFormApiField,
  VersionPayload,
} from '../types/MarketingBox';
import { httpClient, HTTPClientRequestPayload } from '../utils/httpClient';
import {
  PREGENERATE,
  GENERATE,
  REGENERATE,
  FEEDBACK,
  TRACK_CONTENT_COPY,
  PARTIAL_REGENERATE,
  RECOMMEND_IMAGES,
  CONTENT_ASSET,
  LOCALIZATION_TRANSLATE,
  LOCALIZATION_LANGUAGES,
  CANNED_PARAMETERES_BRANDS,
  CANNED_PARAMETERES_AUDIENCES,
  CANNED_PARAMETERES_TONES,
  CANNED_PARAMETERES_CHANNELS,
  CANNED_PARAMETERES_SECTIONS,
  CANNED_PARAMETERES_SEGMENTS,
  CANNED_PARAMETERES_PLATFORMS,
  CANNED_PARAMETERES_PRIMARY_MESSAGES,
  CONTENT_CONFIG,
  CLAIMS_REFERENCES,
  WF_TASK_DETAILS,
  CANNED_PARAMETERES_INDICATIONS,
  CANNED_PARAMETERES_COUNTRIES,
  CANNED_PARAMETERES_MODULES,
  GET_GENERATED_CONTENT,
  CANNED_PARAMETERES_ACTIONS,
  GET_EXTRACTION_DETAILS,
  GET_GENERATED_CONTENT_STATUS,
  SUPPORTING_INFORMATION,
  GENERATE_CAPTION,
  GENERATED_CONTENT_VALIDATION,
  PRIMARY_MESSAGES,
} from '../constants/apiRoutes';
import Logger from '../utils/logger';
import {
  ANALYTICS,
  ContentType,
  GENERATE_CONTENT,
  DestinationFormat,
} from '../constants/common';
import {
  getTransformedBannerContent,
  getTransformedPromptData,
  // getTransformedRegenerationSupportedEmailData,
  getTransformedEmailModuleData,
  getTransformedSocialData,
  getTransformedDSPModuleData,
} from '../utils/apiResponseTransformers';
import { AvialableLanguageResult } from '../types/Language';
import {
  GeneratedContent,
  GeneratedContentDataType,
} from '../types/transformedData/MarkettingContentGeneration';
import { FeedbackResponseData } from '../types/apiResponseData/MarkettingContentGeneration';
import { FormFields, FormFieldsPayload } from '../types/PromptForm';
import {
  trackGenerateContentComplete,
  trackGenerateContentInitiate,
  trackGenerateContentSubmit,
  trackInputValues,
  trackFormComplete,
  trackSystemErrors,
  trackFormError,
} from './Analytics';
import {
  WfTaskDetailsResponseData,
  WfTaskDetailsResult,
} from '../types/WfData';
import { Claim, ClaimsResult, PrefetchedClaimsResponse } from '../types/Claims';
import {
  getEncodedUserNtid,
  getGenerateContentPayload,
  getMappedName,
  getPrefectedClaimListIds,
} from '../store/markettingContentGeneration/helpers/common';
import { marketingEnhancementDefaults } from '../context/MarkettingPromptContext/constants';
import { getContentGroupId, getSessionId } from '../utils/commonUtils';
import {
  ExtractionDetailsFuncResp,
  ExtractionDetailsResponse,
} from '../types/ExtractionDetails';
import { GenerateStatusResponse } from '../types/GenerationStatus';
import { ApiPayloadUsed } from '../store/markettingContentGeneration/stateSegments/generateStateSegment';
import { ParamsUsed } from '../types/store/markettingContentGeneration/majorProcessActions';
import { ModuleContent } from '../types/store/markettingContentGeneration/emailModules';
import { DSPGeneratedContent } from '../components/molecules/DSPRenderer/DSPRenderer.types';
import sortContentsArrayForExport from '../utils/sortContentsArrayForExport';
import { Option } from '../types/SelectInput';
import { addTranslatedLanguageLabelForSelectedModule } from '../utils/addLanguageLabelToTranslatedContent';
import { contentParamsSegment } from '../store/markettingContentGeneration/stateSegments/paramsSegment';
import store from '../store/markettingContentGeneration';

const handleError = (): GeneratedContent => {
  const errorResponse = {
    success: false,
  };
  return errorResponse;
};

// Type guard to check if data contains generatedContent
const isGeneratedContent = (
  data: ContentGeneratedResult['data'] | ContentTranslatedResult['data']
): data is ContentGeneratedResult['data'] => 'generatedContent' in data;

// const getScore = (data: GenerateResultData): number => {
//   const generatedContent = data.generatedContent as
//     | GeneratedBannerContentData[]
//     | GeneratedSocialContentData[];
//   return generatedContent[0]?.score?.value;
// };

const getScore = (
  data: ContentGeneratedResult['data'] | ContentTranslatedResult['data']
): number => {
  if (isGeneratedContent(data)) {
    const generatedContent = data.generatedContent as
      | GeneratedBannerContentData[]
      | GeneratedSocialContentData[];
    return generatedContent[0]?.score?.value;
  }
  const translatedContent = data.translatedContent as
    | GeneratedBannerContentData[]
    | GeneratedSocialContentData[];
  return translatedContent[0]?.score?.value;
};

const getScoreForEmailModules = (content: GeneratedModuleContent[]): number => {
  return content?.[0]?.score.value;
};

// const getContentId = (data: ContentGeneratedResult['data']): string => {
//   const generatedContent = data.generatedContent as
//     | GeneratedBannerContentData[]
//     | GeneratedSocialContentData[];
//   return generatedContent[0].id;
// };

const getContentId = (
  data: ContentGeneratedResult['data'] | ContentTranslatedResult['data']
): string => {
  if (isGeneratedContent(data)) {
    const generatedContent = data.generatedContent as
      | GeneratedBannerContentData[]
      | GeneratedSocialContentData[];
    return generatedContent[0].id;
  }

  const translatedContent = data.translatedContent as
    | GeneratedBannerContentData[]
    | GeneratedSocialContentData[];
  return translatedContent[0].id;
};

const getContentIdForEmailModules = (
  content: GeneratedModuleContent[]
): string => {
  return content?.[0].id || '';
};

const extractGeneratedContentFirstVersion = (
  data: GeneratedModuleContent[] | DSPGeneratedContent[]
) => data?.[0];

export const getGeneratedContentFormatted = (
  contentType: string,
  data: ContentGeneratedResult['data'] | ContentTranslatedResult['data'],
  destinationFormat?: string | undefined | null
): GeneratedContent => {
  const contentKey =
    'generatedContent' in data ? 'generatedContent' : 'translatedContent';

  switch (contentType) {
    case ContentType.BANNER:
      return {
        success: true,
        data: {
          id: getContentId(data),
          score: {
            value: getScore(data),
          },
          markdownText: '',
          content: getTransformedBannerContent(
            get(data, contentKey, []) as GeneratedBannerContentData[]
          ),
        },
      };

    case ContentType.EMAIL:
      return {
        success: true,
        data: {
          id: getContentIdForEmailModules(
            get(data, contentKey, []) as GeneratedModuleContent[]
          ),
          score: {
            value: getScoreForEmailModules(
              get(data, contentKey, []) as GeneratedModuleContent[]
            ),
          },
          markdownText: '',
          content: extractGeneratedContentFirstVersion(
            getTransformedEmailModuleData(
              get(data, contentKey, []) as GeneratedModuleContent[]
            ) as GeneratedModuleContent[]
          ),
        },
      };

    case ContentType.DSP:
      if (
        destinationFormat?.toLowerCase() ===
        DestinationFormat.BANNER.toLowerCase()
      ) {
        return {
          success: true,
          data: {
            id: getContentId(data),
            score: {
              value: getScore(data),
            },
            markdownText: '',
            content: getTransformedBannerContent(
              get(data, contentKey, []) as BannerFramesListType[]
            ),
          },
        };
      }

      if (
        destinationFormat?.toLowerCase() ===
        DestinationFormat.SOCIAL.toLowerCase()
      ) {
        return {
          success: true,
          data: {
            id: getContentId(data),
            score: {
              value: getScore(data),
            },
            markdownText: '',
            content: getTransformedSocialData(get(data, contentKey, [])),
          },
        };
      }
      return {
        success: true,
        data: {
          id: getContentId(data),
          score: {
            value: getScore(data),
          },
          markdownText: '',
          content: extractGeneratedContentFirstVersion(
            getTransformedDSPModuleData(
              get(data, contentKey, []) as DSPGeneratedContent[]
            )
          ),
        },
      };

    case 'social media':
    case 'social':
      return {
        success: true,
        data: {
          id: getContentId(data),
          score: {
            value: getScore(data),
          },
          markdownText: '',
          content: getTransformedSocialData(get(data, contentKey, [])),
        },
      };

    default:
      return {
        success: true,
        data: {
          id: getContentId(data),
          markdownText: JSON.stringify(data),
          score: {
            value: getScore(data),
          },
        },
      };
  }
};

export const fetchPreGeneratedContent = async (
  marketingFormField: MarketingFormFieldRequest
): Promise<ClaimsResult> => {
  const {
    action,
    brand,
    indication,
    country,
    targetAudience,
    segment,
    contentType,
    tone,
    topics,
    language,
    platform,
    emailModules,
    fileName,
    sessionId,
  } = marketingFormField;
  const payload: HTTPClientRequestPayload = {
    action,
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    channel: contentType,
    tone,
    language,
    topic: topics,
    platform,
    fileName,
    emailModules: emailModules?.split(','),
  };

  if (sessionId) {
    payload.sessionId = sessionId;
  }
  try {
    const response = await httpClient.post<PrefetchedClaimsResponse>(
      PREGENERATE,
      payload
    );
    const { data, success } = response as PrefetchedClaimsResponse;
    if (success && data) {
      return { success, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const fetchGeneratedContent = async (
  marketingFormField: MarketingFormFieldRequest & PregenerateClaimsFeild
): Promise<GeneratedContent> => {
  const {
    brand,
    indication,
    country,
    targetAudience,
    segment,
    sections,
    contentType,
    tone,
    topics,
    platform,
    useSubstituteClaims,
    claims,
    emailModules,
    action,
    destinationFormat,
    fileName,
    language,
    sessionId,
    contentGroupId,
  } = marketingFormField;
  const payload: HTTPClientRequestPayload = {
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    sections,
    channel:
      (contentType === ContentType.DSP && destinationFormat) || contentType,
    tone,
    topic: topics,
    platform,
    useSubstituteClaims,
    claims,
    emailModules: emailModules?.split(','),
    action,
    sessionId: sessionId || getSessionId(),
    contentGroupId: contentGroupId || getContentGroupId(),
    fileName,
    language,
  };
  // Tracking analytics for Generate API initiation
  trackGenerateContentInitiate(ANALYTICS.GENERATE_FORM_API_NAME);
  try {
    const response = await httpClient.post<ContentGeneratedResult>(
      GENERATE,
      payload
    );
    const { data, success } = response as ContentGeneratedResult;

    if (success && data) {
      // Tracking analytics for Generate API completion
      // if content type is dsp don't fire this event becuase dsp will poll after this api and
      // after successfull poll it will ask for the generated content
      if (payload.channel !== ContentType.DSP) {
        trackGenerateContentComplete(ANALYTICS.GENERATE_FORM_API_NAME);
      }
      return getGeneratedContentFormatted(contentType, data, destinationFormat);
    }
    // Tracking analytics for system errors from generate API
    trackSystemErrors(ANALYTICS.GENERATE_FORM_API_NAME);
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    // Tracking analytics for system errors from generate API
    trackSystemErrors(ANALYTICS.GENERATE_FORM_API_NAME);
    return handleError();
  }
};

export const generateStatus = async (
  marketingFormField: ApiPayloadUsed,
  contentId: string
): Promise<GenerateStatusResponse> => {
  const { action, sessionId } = marketingFormField;
  try {
    const response = await httpClient.get<GenerateStatusResponse>(
      GET_GENERATED_CONTENT_STATUS(
        sessionId as string,
        action as string,
        contentId as string
      )
    );
    const { data, success } = response as GenerateStatusResponse;
    if (success && data) {
      return { data, success };
    }
    return handleError() as GenerateStatusResponse;
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError() as GenerateStatusResponse;
  }
};

const getExclusionValue = (exclusions: string): string[] => {
  if (!exclusions || !exclusions.trim()) {
    return [];
  }

  return exclusions?.split(',').map((str) => str.trim());
};

export const fetchRegeneratedContent = async (
  marketingFormField: MarketingFormFieldRequest &
    SecondaryFormApiField &
    VersionPayload
): Promise<GeneratedContent> => {
  const {
    brand,
    indication,
    country,
    targetAudience,
    segment,
    sections,
    contentType,
    tone,
    platform,
    topics,
    toneStyle,
    exclusions,
    inclusions,
    versionIds = [],
    parentId,
    emailModules,
    destinationFormat,
    sessionId,
    contentGroupId,
    action,
    fileName,
    language,
    operationType,
  } = marketingFormField;

  const payload: HTTPClientRequestPayload = {
    parentId,
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    sections,
    channel:
      (contentType === ContentType.DSP && destinationFormat) || contentType,
    tone,
    platform,
    topic: topics,
    versionIds,
    emailModules: emailModules?.split(','),
    inclusions,
    action,
    sessionId: contentType === ContentType.DSP ? sessionId : getSessionId(),
    contentGroupId: contentGroupId || getContentGroupId(),
    fileName,
    language,
    operationType,
  };

  if (toneStyle) {
    payload.toneLevel = toneStyle;
  } else {
    // get the default tone level
    payload.toneLevel = getMappedName(
      marketingEnhancementDefaults.currentAvailableToneStyleOptions,
      marketingEnhancementDefaults.currentSelectedToneStyle
    );
  }

  if (exclusions) {
    const exclusionsValue = getExclusionValue(exclusions || '');
    payload.exclusions = exclusionsValue;
  } else {
    payload.exclusions = [];
  }

  // Tracking analytics for Regenerate API initiation
  trackGenerateContentInitiate(ANALYTICS.REGENERATE_FORM_API_NAME);
  try {
    const response = await httpClient.post<ContentGeneratedResult>(
      REGENERATE,
      payload
    );
    const { data, success } = response as ContentGeneratedResult;

    if (success && data) {
      // Tracking analytics for Regenerate API completion
      // if contnent type is dsp don't fire this event becuase dsp will poll after this api and
      // after successfull poll it will ask for the generated content
      if (payload.channel !== ContentType.DSP) {
        trackGenerateContentComplete(ANALYTICS.REGENERATE_FORM_API_NAME);
      }
      return getGeneratedContentFormatted(contentType, data, destinationFormat);
    }
    // Tracking analytics for system errors from regenerate API
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_API_NAME);
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    // Tracking analytics for system errors from regenerate API
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_API_NAME);
    return handleError();
  }
};

export const loadPromptFieldOptions = async (
  formField: FormFields,
  payload: FormFieldsPayload
) => {
  const queryParams = () =>
    Object.entries(payload)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
      )
      .join('&');

  const cannedParamsUrls: Record<string, string> = {
    [FormFields.BRAND]: CANNED_PARAMETERES_BRANDS,
    [FormFields.INDICATION]: CANNED_PARAMETERES_INDICATIONS,
    [FormFields.COUNTRY]: CANNED_PARAMETERES_COUNTRIES,
    [FormFields.AUDIENCE]: CANNED_PARAMETERES_AUDIENCES,
    [FormFields.TONE]: CANNED_PARAMETERES_TONES,
    [FormFields.PRIMARYMESSAGE]: CANNED_PARAMETERES_PRIMARY_MESSAGES,
    [FormFields.CHANNEL]: CANNED_PARAMETERES_CHANNELS,
    [FormFields.SECTION]: CANNED_PARAMETERES_SECTIONS,
    [FormFields.SEGMENT]: CANNED_PARAMETERES_SEGMENTS,
    [FormFields.PLATFORM]: CANNED_PARAMETERES_PLATFORMS,
    [FormFields.MODULE]: CANNED_PARAMETERES_MODULES,
    [FormFields.ACTION]: CANNED_PARAMETERES_ACTIONS,
    [FormFields.DESTINATION_FORMAT]: CANNED_PARAMETERES_CHANNELS,
    [FormFields.LANGUAGE]: LOCALIZATION_LANGUAGES,
    [FormFields.PRIMARY_MESSAGES]: PRIMARY_MESSAGES,
  };

  const url = `${cannedParamsUrls[formField]}?${queryParams()}`;
  try {
    const response = await httpClient.get<CannedParametersResult>(url);

    const { data, success, message } = response as CannedParametersResult;
    return { success, message, data };
  } catch (error) {
    const fetchError = error as ApiError;
    Logger.error(new Error(fetchError.message));
    return handleError();
  }
};

export const updateFeedback = async (
  feedbackPayload: FeedbackData
): Promise<FeedbackResponseData> => {
  const payload: HTTPClientRequestPayload = {
    ...feedbackPayload,
  };

  try {
    const response = await httpClient.post<FeedbackResult>(FEEDBACK, payload);
    const { data, success } = response as FeedbackResult;

    if (success && data) {
      return { success };
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const trackContentCopy = async (
  id: string,
  parentId: string,
  sessionId: string
) => {
  const payload: HTTPClientRequestPayload = {
    id,
    parentId,
    sessionId,
  };

  try {
    const response = await httpClient.post<TrackContentCopyResult>(
      TRACK_CONTENT_COPY,
      payload
    );
    const { success } = response as TrackContentCopyResult;

    if (success) {
      return { data: {}, success };
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const fetchAvailableLanguage = async (selectedCountry: string) => {
  try {
    const response = await httpClient.get<AvialableLanguageResult>(
      `${LOCALIZATION_LANGUAGES}?countryName=${selectedCountry}`
    );
    const { data, success, message } = response as AvialableLanguageResult;
    return { success, message, data };
  } catch (error) {
    const fetchError = error as ApiError;
    Logger.error(new Error(fetchError.message));
    return handleError();
  }
};

const getFormattedDestinationFormat = (destinationFormat?: string) => {
  return destinationFormat === ContentType.SOCIAL
    ? DestinationFormat.SOCIAL
    : destinationFormat;
};

export const fetchTranslatedContent = async ({
  contentId,
  languageId,
  contentType,
  action,
  optionId,
  sections,
  destinationFormat,
}: {
  contentId: number;
  languageId: string;
  contentType: string;
  action?: string;
  optionId?: string;
  sections?: string[];
  destinationFormat?: string;
}): Promise<GeneratedContent> => {
  const formattedDestinationFormat = getFormattedDestinationFormat(
    destinationFormat?.toLowerCase()
  );
  const payload: HTTPClientRequestPayload = {
    id: contentId,
    sessionId: getSessionId(),
    languageId,
    channel:
      (contentType === ContentType.DSP && formattedDestinationFormat) ||
      contentType,
    action,
  };

  if (optionId) {
    const translateOptions = [
      {
        optionId,
        ...(Array.isArray(sections) &&
          sections.length > 0 && { fields: [...sections] }),
      },
    ];

    payload.translateOptions = translateOptions;
  }

  try {
    const response = await httpClient.post<ContentTranslatedResult>(
      LOCALIZATION_TRANSLATE,
      payload
    );
    const { data, success } = response as ContentTranslatedResult;
    if (success && data) {
      return getGeneratedContentFormatted(
        contentType,
        data,
        formattedDestinationFormat
      );
    }
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError();
  }
};

export interface RegenerateSectionDetails {
  optionId: string;
  sections?: string[];
  imageIds?: string[];
}

export const fetchPartialRegeneratedContent = async (
  marketingFormField: MarketingFormField &
    SecondaryFormApiField &
    VersionPayload & { contentId: string },
  regenerateSectionDetails: RegenerateSectionDetails
): Promise<GeneratedContent> => {
  const {
    brand,
    action,
    indication,
    country,
    targetAudience,
    segment,
    sections,
    contentType,
    tone,
    topics,
    exclusions,
    inclusions,
    toneStyle,
    versionIds = [],
    parentId,
    contentId,
    language,
    emailModules,
    destinationFormat,
  } = marketingFormField;
  const payload: HTTPClientRequestPayload = {
    action,
    parentId,
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    sections,
    channel:
      (contentType === ContentType.DSP && destinationFormat) || contentType,
    tone,
    topic: topics,
    versionIds,
    contentId,
    language,
    emailModules: emailModules?.split(','),
    inclusions,
    sessionId: getSessionId(),
    contentGroupId: getContentGroupId(),
  };

  if (toneStyle) {
    payload.toneLevel = toneStyle;
  } else {
    // get the default tone level
    payload.toneLevel = getMappedName(
      marketingEnhancementDefaults.currentAvailableToneStyleOptions,
      marketingEnhancementDefaults.currentSelectedToneStyle
    );
  }

  if (exclusions) {
    const exclusionsValue = getExclusionValue(exclusions || '');
    payload.exclusions = exclusionsValue;
  } else {
    payload.exclusions = [];
  }

  payload.regenerateOptions = [
    {
      optionId: regenerateSectionDetails.optionId,
      fields: regenerateSectionDetails.sections,
    },
  ];

  try {
    const response = await httpClient.post<ContentGeneratedResult>(
      PARTIAL_REGENERATE,
      payload
    );
    const { data, success } = response as ContentGeneratedResult;

    if (success && data) {
      return getGeneratedContentFormatted(contentType, data, destinationFormat);
    }
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError();
  }
};

// image recommendation

const getImagesReferenceFrameIndex = (data: ImageData) => {
  const { imagesReferenceFrame, frames = [] } = data.imagesRecommend[0];

  return (
    frames.findIndex((frame) => frame.id === imagesReferenceFrame) + 1
  ).toString();
};

const getGeneratedImagesFormatted = (
  contentType: string,
  data: ImageData
): GeneratedImageContent => {
  switch (contentType) {
    case ContentType.BANNER:
      return {
        success: true,
        data: {
          optionId: data.imagesRecommend[0].optionId,
          imagesReferenceFrame: getImagesReferenceFrameIndex(data),
          images:
            data.imagesRecommend[0]?.frames?.flatMap((frame) =>
              frame.recommendedImages
                .filter((image) => image.imageName?.trim() !== '')
                .map((image) => {
                  const imageObject: {
                    imageId: string;
                    imageName: string;
                    caption?: string;
                  } = {
                    imageId: image.imageId,
                    imageName: image.imageName,
                  };
                  if (image.caption) {
                    imageObject.caption = image.caption;
                  }
                  return imageObject;
                })
            ) ?? [],
        },
      };
    case 'social':
      return {
        success: true,
        data: {
          optionId: data.imagesRecommend[0].optionId,
          images:
            data.imagesRecommend[0]?.recommendedImages?.map((item) => {
              const imageObject: Image = {
                imageId: item.imageId,
                imageName: item.imageName,
              };
              if (item.caption) {
                imageObject.caption = item.caption;
              }
              return imageObject;
            }) || [],
        },
      };
    case ContentType.EMAIL:
      return {
        success: true,
        data: {
          optionId: data.imagesRecommend[0].optionId,
          images:
            data.imagesRecommend[0]?.recommendedImages?.map((item) => {
              const imageObject: Image = {
                imageId: item.imageId,
                imageName: item.imageName,
              };
              if (item.caption) {
                imageObject.caption = item.caption;
              }
              return imageObject;
            }) || [],
        },
      };
    default:
      return {
        success: false,
      };
  }
};

type Image = { imageId: string; imageName: string; caption?: string };

export type ImageCaptionData = {
  optionId: string;
  imagesReferenceFrame?: string;
  images: Image[];
};

export type GeneratedImageContent = {
  success: boolean;
  data?: ImageCaptionData;
};

const handleImagesError = (): GeneratedImageContent => {
  const errorResponse = {
    success: false,
  };

  return errorResponse;
};

export const fetchRecommendedImages = async (
  formFields: {
    brand: string;
    channel: string;
    contentId: string;
    sessionId: string;
    action: string;
  },
  regenerateSectionDetails: RegenerateSectionDetails
): Promise<GeneratedImageContent> => {
  const payload: HTTPClientRequestPayload = {
    brand: formFields.brand,
    channel: formFields.channel,
    contentId: formFields.contentId,
    sessionId: formFields.sessionId,
    action: formFields.action,
  };

  payload.imageRecommendOptions = [
    {
      optionId: regenerateSectionDetails.optionId,
      fields: regenerateSectionDetails.sections,
    },
  ];

  try {
    const response = await httpClient.post<ContentGeneratedResult>(
      RECOMMEND_IMAGES,
      payload
    );
    const { data, success } = response as ImageApiResponse;

    if (success && data) {
      return getGeneratedImagesFormatted(payload.channel, data);
    }
    return handleImagesError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleImagesError();
  }
};

export const fetchImagesCaption = async (
  formFields: {
    brand: string;
    channel: string;
    contentId: string;
    sessionId: string;
    action: string;
  },
  regenerateSectionDetails: RegenerateSectionDetails
): Promise<GeneratedImageContent> => {
  const payload: HTTPClientRequestPayload = {
    brand: formFields.brand,
    channel: formFields.channel,
    contentId: formFields.contentId,
    sessionId: formFields.sessionId,
    action: formFields.action,
  };

  payload.assetCaptionOptions = [
    {
      optionId: regenerateSectionDetails.optionId,
      imageIds: regenerateSectionDetails.imageIds,
    },
  ];

  try {
    const response = await httpClient.post<ImageApiResponse>(
      GENERATE_CAPTION,
      payload
    );
    const { data, success } = response as ImageApiResponse;

    if (success && data) {
      return getGeneratedImagesFormatted(payload.channel, data);
    }
    return handleImagesError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleImagesError();
  }
};

type DownloadFileResponseData = {
  success: boolean;
  data?: { content: string; fileName?: string };
};

export interface UploadFileResult {
  success: boolean;
  message: string;
  errors?: string[];
  data: unknown;
  headers?: Record<string, string>;
  status: number;
}

export const fetchContentAsset = async (
  imageName: string
): Promise<DownloadFileResponseData | ApiError> => {
  try {
    const headers = {
      Accept: 'application/octet-stream',
    };
    const response = (await httpClient.get<UploadFileResult>(
      CONTENT_ASSET(imageName),
      {},
      headers,
      'arraybuffer'
    )) as UploadFileResult;

    const { data, success, headers: responseHeaders } = response;

    if (success && data && responseHeaders) {
      return {
        data: { content: data, fileName: 'imageName' },
        success,
      } as DownloadFileResponseData;
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const fetchReferences = async (
  contentId: string,
  sessionId: string,
  action: string
): Promise<ReferencesResponse> => {
  Logger.info(contentId);
  try {
    const response = await httpClient.get<ReferencesResult>(
      CLAIMS_REFERENCES(contentId, sessionId, action)
    );

    const { data, success } = response as ReferencesResult;
    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    const fetchError = error as ApiError;
    Logger.error(new Error(fetchError.message));
    return {
      success: false,
    };
  }
};

export type ContentConfig = {
  success: boolean;
  data: {
    contentConfig: {
      recommendImages: false;
    };
  };
};

export const fetchContentConfig = async (formFields: MarketingFormField) => {
  try {
    const response = (await httpClient.get<ContentConfig>(
      CONTENT_CONFIG(
        formFields.brand,
        formFields.targetAudience,
        formFields.contentType
      ),
      {}
    )) as ContentConfig;
    const { data, success } = response;

    if (success && data) {
      return { success, data };
    }

    return handleError();
  } catch (error) {
    const getError = error as ApiError;
    Logger.error(new Error(getError.message));
    return handleError();
  }
};

export const fetchWFTaskDetails = async (
  taskId: string,
  projectId: string
): Promise<WfTaskDetailsResponseData> => {
  try {
    const response = await httpClient.get<WfTaskDetailsResult>(
      WF_TASK_DETAILS(taskId, projectId)
    );
    const { success, data } = response as WfTaskDetailsResult;

    if (success && data) {
      return { success, data: getTransformedPromptData(data) };
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const getGeneratedContent = async ({
  action,
  sessionId,
  contentId,
  contentGroupId,
}: GetGeneratedContentParams): Promise<GeneratedContentResult> => {
  try {
    const response = await httpClient.get<EmailContentResult>(
      GET_GENERATED_CONTENT(sessionId, action, contentId, contentGroupId)
    );
    const { data, success } = response as GeneratedContentResult;
    if (success && data) {
      return {
        success,
        data,
      };
    }
    return handleError() as GeneratedContentResult;
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError() as GeneratedContentResult;
  }
};

export const getExtractionDetails = async (
  sessionId: string
): Promise<ExtractionDetailsFuncResp> => {
  try {
    const response = await httpClient.get<ExtractionDetailsResponse>(
      GET_EXTRACTION_DETAILS(sessionId)
    );
    const { data, success } = response as ExtractionDetailsResponse;
    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return handleError() as unknown as ExtractionDetailsFuncResp;
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError() as unknown as ExtractionDetailsFuncResp;
  }
};

/**
 * Fetches images for a list of recommended image names and returns an array of image data.
 *
 * @param {string[]} recommendedImages - An array of recommended image names.
 * @returns {Promise<ContentImages[]>} A promise that resolves to an array of image data.
 */
const fetchImagesForGeneratedContent = async (
  recommendedImages: string[]
): Promise<ContentImages[]> => {
  const images = await Promise.all(
    recommendedImages.map(async (imageName) => {
      try {
        const { success, data: contentAssetData } =
          await fetchContentAsset(imageName);

        if (success && contentAssetData) {
          const fileContent = get(contentAssetData, 'content', '');
          const fileExtension = imageName.split('.').pop() || '';
          const blobType = `image/${fileExtension}`;

          try {
            const blob = new Blob([fileContent as BlobPart], {
              type: blobType,
            });

            return {
              imageBlob: blob,
              imageName,
              imageType: fileExtension as string,
            };
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error converting blob to data URL');
            Logger.error(e as Error);
            return null;
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Error fetching content asset');
          return null;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error fetching content asset');
        Logger.error(e as Error);
        return null;
      }
    })
  );

  return images.filter((image) => image !== null) as ContentImages[];
};

/**
 * Asynchronously fetches recommended images for each Email Module content item in the provided
 * `GeneratedModuleContent` array, converts image data into blobs, and updates the content
 * data structure with the images data (image blob, image name and image type) for each option.
 *
 * @param {GeneratedModuleContent[]} generatedContent - An array of generated content data objects.
 * Each object contains module content information with potential recommended images.
 * @param {ImagesRecommended[]} imagesRecommended - An array of recommended images for each content item.
 * @param {boolean} [includeImages=false] - A flag indicating whether to include images in the exported file.
 * @returns {Promise<GeneratedModuleContent[]>} - A promise that resolves to a copy of the
 * provided data array, where each content item is updated with the fetched images data.
 */
export const fetchImagesAndUpdateDataForExport = async (
  generatedContent: GeneratedModuleContent[],
  imagesRecommended: ImagesRecommended[] = [],
  includeImages = false
): Promise<GeneratedModuleContent[]> => {
  const transformedEmailModuleContent =
    getTransformedEmailModuleData(generatedContent) || [];

  const updatedEmailModulesContent = await Promise.all(
    transformedEmailModuleContent?.map(async (emailContent) => {
      const { id, contentOptions = [] } = emailContent;

      const contentImagesData = imagesRecommended?.find(
        (recommendedImages) => recommendedImages.id === id
      );

      const options = [...contentOptions];

      if (
        contentImagesData &&
        contentImagesData?.contentImages?.length &&
        includeImages
      ) {
        const { contentImages } = contentImagesData;

        await Promise.all(
          contentImages.map(async (contentImage) => {
            const { optionId, recommendedImages } = contentImage;

            const images =
              await fetchImagesForGeneratedContent(recommendedImages);

            const option = options?.find((opt) => opt?.optionId === optionId);

            if (option) {
              option.images = images;
            }
          })
        );
      }

      return {
        ...emailContent,
        contentOptions: options,
      };
    })
  );

  return updatedEmailModulesContent;
};

/**
 * For /generated-content API response, i.e., when the /generated-content API response
 * is getting consumed for Email Modules Export functionality.
 */
export interface ContentResponse extends GeneratedContentResult {
  isNewContent?: boolean;
}

/**
 * For /generated API response, i.e., when the /generated API response
 * is getting consumed for Email Modules Export functionality.
 */
export interface GenerateContentResponse extends GeneratedContent {
  isNewContent?: boolean;
}

// Makes the /generated-content API call
/**
 * Fetches pre-generated content for an Email Module for export functionality by making an API call to the `/generated-content` endpoint.
 *
 * This asynchronous function retrieves the generated content using the module's `parentContentId` and session ID.
 * It also updates the translated content with language labels based on the current available languages and translation configuration.
 *
 * @param {ModuleContent} module - The module object containing details of an Email Module such as `parentContentId` and `translationConfig`
 * (from the `moduleContentsStateSegment` Jotai atom).
 * @param {Option[]} currentAvailableLanguages - An array of available languages, each with `id`, `label` and other properties.
 * @param {boolean} [includeTranslated=false] - A flag indicating whether to include translated content in the exported file.
 * @returns {Promise<ContentResponse>} A promise that resolves to the `ContentResponse` object containing the fetched and updated content.
 */
export const fetchPregeneratedContentForExport = async (
  module: ModuleContent,
  currentAvailableLanguages: Option[],
  includeTranslated = false
): Promise<ContentResponse> => {
  const sessionId = getSessionId();
  const contentGroupId = getContentGroupId();
  const { parentContentId } = module || {};

  const generatedContent: ContentResponse = await getGeneratedContent({
    action: GENERATE_CONTENT,
    contentGroupId,
    sessionId,
    contentId: parentContentId,
  });

  generatedContent.isNewContent = false;

  const { data, success } = generatedContent || {};

  if (data && success) {
    if (module && Object.keys(module)?.length > 0 && includeTranslated) {
      const { translationConfig } = module || {};

      const sortedContentsArray = sortContentsArrayForExport(
        generatedContent?.data?.generatedContent as GeneratedModuleContent[]
      );

      const translatedContentsArray = generatedContent?.data
        ?.translatedContent as GeneratedModuleContent[];

      let updatedTranslatedContentsArray: TranslatedContent[] = [];

      if (
        translationConfig &&
        Object.keys(translationConfig)?.length > 0 &&
        generatedContent?.data?.translatedContent &&
        generatedContent?.data?.translatedContent?.length > 0
      ) {
        updatedTranslatedContentsArray =
          addTranslatedLanguageLabelForSelectedModule(
            sortedContentsArray,
            translatedContentsArray,
            translationConfig,
            currentAvailableLanguages
          );

        generatedContent.data.translatedContent =
          updatedTranslatedContentsArray;
      }
    }

    return generatedContent as unknown as ContentResponse;
  }

  throw new Error(
    `Error fetching the generated content for ${
      module?.label && module?.label?.trim() !== '' ? `${module?.label} ` : ''
    }Email Module for export.`
  );
};

// Makes the /generate API call
/**
 * Generates content for an Email Module, for export functionality, by making an API call to the `/generate` endpoint.
 *
 * This asynchronous function constructs a payload using the data filled in the Marketing form and claim details,
 * then makes an API call to the `/generate` endpoint to fetch the generated content. If successful, it returns
 * the content along with a success flag and marks it as new content.
 *
 * @param {ParamsUsed} paramsUsed - An object containing the data filled in the Marketing form, used to generate the content.
 * @param {PregenerateClaimsFeild} claimsDetail - An object containing claims details required for content generation.
 * @returns {Promise<GenerateContentResponse>} A promise that resolves to a `GenerateContentResponse` object containing the generated content and success status.
 *
 * @throws Will throw an error if the content generation fails.
 */
export const generateContentForExport = async (
  paramsUsed: ParamsUsed,
  claimsDetail: PregenerateClaimsFeild
): Promise<GenerateContentResponse> => {
  const generatePayload = getGenerateContentPayload(paramsUsed);

  const payload = {
    ...generatePayload,
    ...claimsDetail,
  };

  const { data, success } = await fetchGeneratedContent(payload);

  if (success) {
    return { data, success, isNewContent: true };
  }

  throw new Error(
    `Error generating content for ${
      payload &&
      payload?.emailModules &&
      typeof payload?.emailModules === 'string'
        ? `${payload?.emailModules} `
        : ''
    }Email Module for export.`
  );
};

// =======================================================
// All Email Modules export functionality code BEGINS here
// =======================================================

// Will decide whether to call /generate or /generated-content API for extracting the content for Export functionality
/**
 * Determines whether to call the `/generate` or `/generated-content` API to extract content for Email Modules for export functionality.
 *
 * This asynchronous function processes an array of module contents and decides which API to call based on the presence
 * of `parentContentId`. It uses pre-fetched claims and parameter values to customize the content generation process.
 * The function returns a promise that resolves to an array of results, indicating the success or failure of each
 * Email Module content generation or content fetching operation.
 *
 * @param {ModuleContent[]} modulesContent - An array of module content objects to be processed for export.
 * @param {Claim[]} preFetchedclaimsList - A list of claims that have been pre-fetched and may influence content generation.
 * @param {ParamsUsed} paramsSegmentValue - Parameters used to customize the content generation process.
 * @param {Option[]} currentAvailableLanguages - An array of available languages, each with `id`, `label` and other properties.
 * @param {boolean} [includeTranslated=false] - A flag indicating whether to include translated content in the exported file.
 * @returns {Promise<PromiseSettledResult<GenerateContentResponse | ContentResponse>[]>} A promise that resolves to an array of settled results, each representing the outcome of a content generation or fetching operation.
 *
 * @example
 * const modules = [{ name: 'Module1', parentContentId: '123' }, { name: 'Module2' }];
 * const claims = [{ id: 'claim1', isSubstitute: false }];
 * const params = { someParam: 'value' };
 * const languages = [{ id: 'en', label: 'English' }];
 * generateContentForAllModules(modules, claims, params, languages).then(results => {
 *   results.forEach(result => {
 *     if (result.status === 'fulfilled') {
 *       console.log('Content fetched or generated successfully:', result.value);
 *     } else {
 *       console.error('Error fetching or generating content:', result.reason);
 *     }
 *   });
 * });
 */
export const generateContentForAllModules = async (
  modulesContent: ModuleContent[],
  preFetchedclaimsList: Claim[],
  paramsSegmentValue: ParamsUsed,
  currentAvailableLanguages: Option[],
  includeTranslated = false
): Promise<
  PromiseSettledResult<GenerateContentResponse | ContentResponse>[]
> => {
  // Map over each module content to create a promise for content generation or fetching
  const contentPromises = modulesContent.map((module) => {
    const { parentContentId, name: moduleName } = module || {};

    // If `parentContentId` exists for the Email Module, fetch pre-generated content
    if (parentContentId && parentContentId.trim() !== '') {
      return fetchPregeneratedContentForExport(
        module,
        currentAvailableLanguages,
        includeTranslated
      );
    }

    const shouldUseSubstitute = preFetchedclaimsList.some(
      (el) => el.isSubstitute
    );

    const claimsFields = {
      sessionId: getSessionId(),
      useSubstituteClaims: shouldUseSubstitute,
      claims: getPrefectedClaimListIds(),
    };

    return generateContentForExport(
      { ...paramsSegmentValue, currentSelectedModules: moduleName },
      claimsFields as unknown as PregenerateClaimsFeild
    );
  });

  // Return a promise that resolves when all content promises have settled
  return Promise.allSettled(contentPromises);
};

// for All Modules
/**
 * This asynchronous function processes an array of module contents, and updates the value of the `moduleContentsStateSegment` Jotai atom
 * if new content has been generated for an Email Module, or optionally fetches and adds each recommended image's data for each option,
 * if pre-generated content has been fetched for an Email Module.
 *
 * @param {ModuleContent[]} modulesContent - An array of module content objects to be processed for state updates.
 * @param {Claim[]} preFetchedclaimsList - A list of claims that have been pre-fetched and may influence content generation.
 * @param {ParamsUsed} paramsSegmentValue - Parameters used to customize the content generation process.
 * @param {Map<string, ModuleContent>} emailModuleContents - A map of current email module contents keyed by a string identifier.
 * @param {SetAtom<SetStateActionWithReset<Map<string, ModuleContent>>, void>} setEmailModuleContents - A setter function to update the state of email module contents.
 * @param {Option[]} currentAvailableLanguages - An array of available languages, each with `id`, `label` and other properties.
 * @param {boolean} [includeImages=false] - A flag indicating whether to include images in the exported file.
 * @param {boolean} [includeTranslated=false] - A flag indicating whether to include translated content in the exported file.
 * @returns {Promise<PromiseSettledResult<GenerateContentResponse | ContentResponse>[]>} A promise that resolves to an array of settled results, each representing the outcome of a content generation or fetching operation.
 */
export const updateModuleContentsStateSegment = async (
  modulesContent: ModuleContent[],
  preFetchedclaimsList: Claim[],
  paramsSegmentValue: ParamsUsed,
  emailModuleContents: Map<string, ModuleContent>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setEmailModuleContents: (args_0: any) => void,
  currentAvailableLanguages: Option[],
  includeImages = false,
  includeTranslated = false
): Promise<
  PromiseSettledResult<GenerateContentResponse | ContentResponse>[]
> => {
  type ExtendedGeneratePayload = MarketingFormFieldRequest &
    PregenerateClaimsFeild;

  function processModuleContents(
    modules: ModuleContent[]
  ): ExtendedGeneratePayload | null {
    let firstGeneratePayload: ExtendedGeneratePayload | null = null;

    const names = modules.reduce<string[]>((acc, module) => {
      if (!firstGeneratePayload && module.generatePayload) {
        // Use type assertion to include emailModules
        firstGeneratePayload = {
          ...module.generatePayload,
          emailModules: '',
        } as ExtendedGeneratePayload;
      }
      acc.push(module.name);
      return acc;
    }, []);

    const emailModules = names.join(', ');

    if (firstGeneratePayload) {
      (firstGeneratePayload as ExtendedGeneratePayload).emailModules =
        emailModules;
    }

    return firstGeneratePayload;
  }
  const payloadForTracking = processModuleContents(modulesContent);

  // track generate form initate event
  trackGenerateContentInitiate(ANALYTICS.GENERATE_FORM_NAME);
  // track the generate content submit here
  // track submit value here

  if (payloadForTracking) {
    const { brand, contentType } = payloadForTracking;

    trackGenerateContentSubmit(
      ANALYTICS.GENERATE_FORM_NAME,
      brand,
      contentType,
      getEncodedUserNtid()
    );
  }

  try {
    // Generate new content or fetch pre-generated content for all modules
    const responses = await generateContentForAllModules(
      modulesContent,
      preFetchedclaimsList,
      paramsSegmentValue,
      currentAvailableLanguages,
      includeTranslated
    );

    // Create a new map to store updated module contents
    const updatedModuleContentsStateSegment = new Map(emailModuleContents);

    await Promise.all(
      // Promise.all to ensure we await all promises
      responses.map(async (response, index) => {
        if (response.status === 'fulfilled') {
          const {
            value: { isNewContent },
          } = response;

          if (isNewContent) {
            // Handle new content generated by the `/generate` API call
            const module = modulesContent[index];
            if (
              !module.parentContentId ||
              module.parentContentId.trim() === ''
            ) {
              const updatedModule: ModuleContent = {
                ...module,
                parentContentId:
                  (response?.value as GenerateContentResponse)?.data?.id ?? '',
                hasContentGenerated: true,
                status: 'In Progress',
              };

              updatedModuleContentsStateSegment.set(
                module.mapKey,
                updatedModule
              );
            }
          } else {
            // Handle existing content from the `/generated-content` API call
            const { data } = (response?.value as ContentResponse) || {};

            if (data && data.generatedContent) {
              const transformedImagesData: ImagesRecommended[] =
                data.generatedContent.map((content) => ({
                  id: content.id,
                  parentId: content.parentId,
                  versionSequence: content.versionSequence,
                  contentImages:
                    content.imagesRecommend?.map((imageRec) => ({
                      optionId: imageRec.optionId,
                      recommendedImages:
                        imageRec?.recommendedImages?.map(
                          (img) => img.imageName
                        ) || [],
                    })) || [],
                }));

              data.generatedContent = await fetchImagesAndUpdateDataForExport(
                data.generatedContent as GeneratedModuleContent[],
                transformedImagesData,
                includeImages
              );

              data.translatedContent = await fetchImagesAndUpdateDataForExport(
                data.translatedContent as GeneratedModuleContent[],
                transformedImagesData,
                includeImages
              );
            }
          }
        } else if (response.status === 'rejected') {
          // If content fetching/generation fails for any module, then what should
          // go in the Word document for that module - that needs to come here.
          // eslint-disable-next-line no-console
          console.error(
            `Error updating module contents for index ${index}:`,
            response.reason
          );
        }
      })
    );

    if (payloadForTracking) {
      const { useSubstituteClaims, ...restPayload } = payloadForTracking;
      const { currentSelectedLanguage } = store.get(contentParamsSegment);
      const generateInputValues = {
        ...restPayload,
        'secondary-claims': useSubstituteClaims ? ANALYTICS.YES : ANALYTICS.NO,
        language: currentSelectedLanguage,
        [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
      };

      // track input values here against the generate form
      trackInputValues(
        ANALYTICS.GENERATE_FORM_NAME,
        ANALYTICS.STEP,
        generateInputValues
      );

      // track content complete
      trackFormComplete(ANALYTICS.GENERATE_FORM_NAME);
    }

    // Update the state of email module contents
    setEmailModuleContents(updatedModuleContentsStateSegment);
    return responses;
  } catch (error) {
    // track generate content error here
    trackFormError(
      ANALYTICS.GENERATE_FORM_NAME,
      'failed to load all modules content'
    );
    // eslint-disable-next-line no-console
    console.error('Error updating module contents:', error);
    return [];
  }
};

// =====================================================
// All Email Modules export functionality code ENDS here
// =====================================================

// =========================================================
// Single Email Module export functionality code BEGINS here
// =========================================================

/**
 * Determines whether to call the `/generate` or `/generated-content` API to extract content for a single Email Module for export functionality.
 *
 * This function checks if a `parentContentId` is provided to determine the appropriate API call. If a valid `parentContentId`
 * is present, it fetches pre-generated content by triggering the `/generated-content` API. Otherwise, it generates new content
 * by invoking the `/generate` API, using specified parameters and claims. The function also considers available languages to
 * ensure content is fetched or generated in the desired language.
 *
 * @param {ModuleContent} selectedModuleContent - The module content object containing details such as `parentContentId` and `name`.
 * @param {Claim[]} preFetchedclaimsList - A list of claims that have been pre-fetched and may influence content generation.
 * @param {ParamsUsed} paramsSegmentValue - Parameters used to customize the content generation process.
 * @param {Option[]} currentAvailableLanguages - An array of available languages, each with `id`, `label` and other properties.
 * @param {boolean} [includeTranslated=false] - A flag indicating whether to include translated content in the exported file.
 * @returns {Promise<GenerateContentResponse | ContentResponse>} A promise that resolves to either a `GenerateContentResponse` or `ContentResponse` object, depending on the API call made.
 *
 * @example
 * const moduleContent = { parentContentId: '12345', name: 'Module1' };
 * const claims = [{ id: 'claim1', isSubstitute: false }];
 * const params = { someParam: 'value' };
 * const languages = [{ id: 'en', label: 'English' }];
 * getSingleModuleContents(moduleContent, claims, params, languages).then(response => {
 *   console.log('Content retrieved:', response);
 * });
 */
export const getSingleModuleContents = (
  selectedModuleContent: ModuleContent,
  preFetchedclaimsList: Claim[],
  paramsSegmentValue: ParamsUsed,
  currentAvailableLanguages: Option[],
  includeTranslated = false
): Promise<GenerateContentResponse | ContentResponse> => {
  const { parentContentId, name: moduleName } = selectedModuleContent || {};
  // Check if a valid parentContentId is provided
  if (parentContentId && parentContentId.trim() !== '') {
    // Fetch pre-generated content by triggering the `/generated-content` API
    return fetchPregeneratedContentForExport(
      selectedModuleContent,
      currentAvailableLanguages,
      includeTranslated
    );
  }

  const shouldUseSubstitute = preFetchedclaimsList.some(
    (el) => el.isSubstitute
  );

  const claimsFields = {
    sessionId: getSessionId(),
    useSubstituteClaims: shouldUseSubstitute,
    claims: getPrefectedClaimListIds(),
  };

  return generateContentForExport(
    { ...paramsSegmentValue, currentSelectedModules: moduleName },
    claimsFields as unknown as PregenerateClaimsFeild
  );
};

// for Single Module
/**
 * This asynchronous function processes the contents of an Email Module, and updates the value of the `moduleContentsStateSegment` Jotai atom
 * if new content has been generated for that particular Email Module, or optionally fetches and adds each recommended image's data for each option,
 * if pre-generated content has been fetched for that particular Email Module.
 *
 * @param {ModuleContent} selectedModuleContent - The module content object to be processed for state updates.
 * @param {Claim[]} preFetchedclaimsList - A list of claims that have been pre-fetched and may influence content generation.
 * @param {ParamsUsed} paramsSegmentValue - Parameters used to customize the content generation process.
 * @param {Map<string, ModuleContent>} emailModuleContents - A map of current email module contents keyed by a string identifier.
 * @param {SetAtom<SetStateActionWithReset<Map<string, ModuleContent>>, void>} setEmailModuleContents - A Jotai function to update the state of email module contents.
 * @param {Option[]} currentAvailableLanguages - An array of available languages, each with `id`, `label` and other properties.
 * @param {boolean} [isCurrentModule=false] - A flag indicating whether the current module is being processed, affecting image fetching behavior.
 * @param {boolean} [includeImages=false] - A flag indicating whether to include images in the exported file.
 * @param {boolean} [includeTranslated=false] - A flag indicating whether to include translated content in the exported file.
 * @returns {Promise<GenerateContentResponse | ContentResponse>} A promise that resolves to either a `GenerateContentResponse` or `ContentResponse` object, depending on the API call made.
 */
export const updateModuleContentsStateSegmentForSingleModule = async (
  selectedModuleContent: ModuleContent,
  preFetchedclaimsList: Claim[],
  paramsSegmentValue: ParamsUsed,
  emailModuleContents: Map<string, ModuleContent>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setEmailModuleContents: (args_0: any) => void,
  currentAvailableLanguages: Option[],
  isCurrentModule = false,
  includeImages = false,
  includeTranslated = false
): Promise<GenerateContentResponse | ContentResponse> => {
  function getFirstGeneratePayload(
    modules: Map<string, ModuleContent>
  ): (MarketingFormFieldRequest & PregenerateClaimsFeild) | null {
    // Convert map values to an array and use find to locate the first non-null generatePayload
    const moduleContentArray = Array.from(modules.values());
    const foundModule = moduleContentArray.find(
      (moduleContent) => moduleContent.generatePayload !== null
    );

    return foundModule ? foundModule.generatePayload : null;
  }

  try {
    const response = await getSingleModuleContents(
      selectedModuleContent,
      preFetchedclaimsList,
      paramsSegmentValue,
      currentAvailableLanguages,
      includeTranslated
    );

    const updatedModuleContentsStateSegment = new Map(emailModuleContents);

    const { isNewContent } = response;

    if (isNewContent) {
      // track generate form initate event
      trackGenerateContentInitiate(ANALYTICS.GENERATE_FORM_NAME);

      const payloadForTracking = getFirstGeneratePayload(emailModuleContents);

      // track the generate content submit here
      // track submit value here
      if (payloadForTracking) {
        const { brand, contentType } = payloadForTracking;
        const { currentSelectedLanguage } = store.get(contentParamsSegment);

        trackGenerateContentSubmit(
          ANALYTICS.GENERATE_FORM_NAME,
          brand,
          contentType,
          getEncodedUserNtid()
        );

        const { useSubstituteClaims, ...restPayload } = payloadForTracking;
        const generateInputValues = {
          ...restPayload,
          'secondary-claims': useSubstituteClaims
            ? ANALYTICS.YES
            : ANALYTICS.NO,
          language: currentSelectedLanguage,
          emailModules: selectedModuleContent.name, // email module which was selected
          [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
        };

        // track input values here against the generate form
        trackInputValues(
          ANALYTICS.GENERATE_FORM_NAME,
          ANALYTICS.STEP,
          generateInputValues
        );

        // track content complete
        trackFormComplete(ANALYTICS.GENERATE_FORM_NAME);
      }

      // for /generate API call
      const module = selectedModuleContent;

      if (!module.parentContentId || module.parentContentId.trim() === '') {
        const updatedModule: ModuleContent = {
          ...module,
          parentContentId:
            (response?.data as GeneratedContentDataType)?.id ?? '',
          hasContentGenerated: true,
          status: 'In Progress',
        };

        updatedModuleContentsStateSegment.set(module.mapKey, updatedModule);
      }
    } else {
      // for /generated-content API call
      const { data } = (response as GeneratedContentResult) || {};

      if (data && data.generatedContent && !isCurrentModule) {
        const transformedImagesData: ImagesRecommended[] =
          data.generatedContent.map((content) => ({
            id: content.id,
            parentId: content.parentId,
            versionSequence: content.versionSequence,
            contentImages:
              content.imagesRecommend?.map((imageRec) => ({
                optionId: imageRec.optionId,
                recommendedImages:
                  imageRec?.recommendedImages?.map((img) => img.imageName) ||
                  [],
              })) || [],
          }));

        data.generatedContent = await fetchImagesAndUpdateDataForExport(
          data.generatedContent as GeneratedModuleContent[],
          transformedImagesData,
          includeImages
        );

        data.translatedContent = await fetchImagesAndUpdateDataForExport(
          data.translatedContent as GeneratedModuleContent[],
          transformedImagesData,
          includeImages
        );
      }
    }

    setEmailModuleContents(updatedModuleContentsStateSegment);
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating module contents:', error);
    return {} as GenerateContentResponse | ContentResponse;
  }
};

// =======================================================
// Single Email Module export functionality code ENDS here
// =======================================================

export type SupportingInformation = {
  success: boolean;
  data: {
    supportingInfo: {
      prescribingInfo: Array<{
        id: string;
        label: string;
        link: string;
      }>;
      safetyInfo: {
        id: string;
        text: string;
      };
    };
  };
};

export const fetchSupportingInformation = async ({
  brandName,
  countryName,
  indicationName,
  audienceName,
}: {
  brandName: string;
  countryName: string;
  indicationName?: string;
  audienceName: string;
}) => {
  try {
    const response = (await httpClient.get<SupportingInformation>(
      SUPPORTING_INFORMATION,
      {
        brandName,
        countryName,
        indicationName,
        audienceName,
      }
    )) as SupportingInformation;
    const { data, success } = response;

    if (success && data) {
      return { success, data } as SupportingInformation;
    }

    return handleError();
  } catch (error) {
    const getError = error as ApiError;
    Logger.error(new Error(getError.message));
    return handleError();
  }
};

type GeneratedContentValidation = {
  success: boolean;
  message: string;
  errors: string[];
  data: {
    sessionId: string;
    contentGroupId: string;
    action: string;
    channel: string;
    generatedContentValidation: {
      id: string;
      validations: {
        type: string;
        success: boolean;
        errors: string[];
      }[];
    }[];
  };
};

export const fetchGeneratedContentValidation = async ({
  contentId,
  sessionId,
  contentGroupId,
  action,
}: {
  contentId: string;
  sessionId: string;
  contentGroupId: string;
  action: string;
}) => {
  try {
    const response = (await httpClient.post<GeneratedContentValidation>(
      GENERATED_CONTENT_VALIDATION,
      {
        contentId,
        sessionId,
        contentGroupId,
        action,
      }
    )) as GeneratedContentValidation;
    const { data, success } = response;

    if (success && data) {
      return { success, data } as GeneratedContentValidation;
    }

    return handleError();
  } catch (error) {
    const getError = error as ApiError;
    Logger.error(new Error(getError.message));
    return handleError();
  }
};
