import store from '../..';
import { GET_CLAIM_DETAILS } from '../../../../constants/apiRoutes';
import { ApiError } from '../../../../types/Content';
import { httpClient } from '../../../../utils/httpClient';
import Logger from '../../../../utils/logger';
import {
  ApiPayloadUsed,
  brandInformationStateSegment,
  ClaimDetails,
} from '../../stateSegments/brandsInformationStateSegment';

export interface ClaimResponse {
  success: boolean;
  data?: {
    claimDetails: ClaimDetails[] | [];
  };
}

export const getClaimDetails = async (
  apiPayload: ApiPayloadUsed
): Promise<ClaimResponse> => {
  const {
    brand: brandName,
    country: countryName,
    indication: indicationName,
    targetAudience: audiences,
    language,
    topics: primaryMessages,
    claimsPage,
    claimsPageSize,
  } = apiPayload;
  try {
    const response = await httpClient.get<ClaimResponse>(
      GET_CLAIM_DETAILS({
        countryName,
        brandName,
        indicationName,
        audiences,
        primaryMessages,
        language,
        page: claimsPage,
        pageSize: claimsPageSize,
      })
    );
    const { data, success } = response as ClaimResponse;

    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const fetchClaimDetails = async (payload: ApiPayloadUsed) => {
  store.set(brandInformationStateSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
    };
  });

  const { success, data } = await getClaimDetails({
    ...payload,
  });

  if (success && data) {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        claimDetails: data.claimDetails,
      };
    });
  } else {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        errorFetchSegment: true,
        errorMessage: 'There has been an error in fetching segment details',
      };
    });
  }
};
